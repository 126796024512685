// 指定されたIDにスクロールトップの機能を持たせる
// idName : id名
// offset ：トップからの距離

export const scrollToPagetopButton = (idName, offset = 0) => {
    const pagetopBtn = document.getElementById(idName);
    pagetopBtn.addEventListener("click", () => {
        window.scrollTo({
            top: offset,
            behavior: "smooth",
        });
    });
};
