import { throttle } from './throttle';

// ボタンのクラス名、追加するクラス名（配列）、スクロール量
export const showScrollToPagetopButton = (buttonClass, addClass, offset) => {
  const buttonNode = document.getElementsByClassName(buttonClass);

  if (buttonNode.length == 0) {
    return;
  }
  const addClassArr = addClass;

  let scroll = 0;
  const toggleClass = function () {
    scroll = window.scrollY;
    const changePosY = offset;

    if (scroll > changePosY) {
      buttonNode[0].classList.add(...addClassArr);
    } else {
      buttonNode[0].classList.remove(...addClassArr);
    }
  };

  const fps = 60;
  window.addEventListener(
    'scroll',
    throttle(() => toggleClass(), 1000 / fps)
  );
  window.addEventListener('DOMContentLoaded', toggleClass());
  window.addEventListener('resize', toggleClass());
};
