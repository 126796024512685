export const mvSlider = new Swiper('.js-mvSlider.swiper', {
  effect: 'fade',
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  speed: 1200,
  on: {
    resize: function () {
      mvSlider.autoplay.start();
    },
    change: function () {
      lastScrollPosition = window.scrollY || document.documentElement.scrollTop;
    },
  },
});
