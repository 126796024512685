export const toggleHamburger = (
  hamburgerButtonClassName,
  spMenuClassName,
  breakPoint
) => {
  const triggerNode = document.getElementsByClassName(hamburgerButtonClassName);
  const menuNode = document.getElementsByClassName(spMenuClassName);
  const linkNode = Array.from(
    document.querySelectorAll(`.${spMenuClassName} a`)
  );

  if (triggerNode.length == 0 || menuNode.length == 0 || linkNode.length == 0) {
    return;
  }

  const pointHeader = window.matchMedia(
    `screen and (min-width: ${breakPoint}px)`
  );

  // 最初の1つに適用
  triggerNode[0].addEventListener('click', function (e) {
    const isExpanded = e.target.getAttribute('aria-expanded');
    if (isExpanded === 'false') {
      openMenu();
    } else {
      closeMenu();
    }
  });

  const openMenu = () => {
    triggerNode[0].setAttribute('aria-expanded', true);
    triggerNode[0].setAttribute('aria-label', 'メニューを閉じる');
    triggerNode[0].classList.add('_is-open');
    menuNode[0].classList.add('_is-open');
  };

  const closeMenu = () => {
    triggerNode[0].setAttribute('aria-expanded', false);
    triggerNode[0].setAttribute('aria-label', 'メニューを開く');
    triggerNode[0].classList.remove('_is-open');
    menuNode[0].classList.remove('_is-open');
  };

  //ブレイクポイントをまたいだ時
  function checkBreakPoint() {
    if (pointHeader.matches) {
      closeMenu();
    }
  }
  pointHeader.addListener(checkBreakPoint);
};
