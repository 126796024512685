import { toggleHamburger } from './toggleHamburger';
import { mvSlider } from './sliders';
import { showScrollToPagetopButton } from './showScrollToPagetopButton';
import { scrollToPagetopButton } from './scrollToPagetopButton';

document.addEventListener('DOMContentLoaded', function () {
  // js-bogoクラスを持つ要素（ボタン）を選択
  let button = document.querySelector('.js-bogo'); // ここでは単一の要素を想定

  // ボタンが存在する場合のみイベントリスナーを設定
  if (button) {
    button.addEventListener('click', function () {
      // js-switcherの子要素の.bogo-language-switcherクラスを持つ要素を選択
      let element = document.querySelector(
        '.js-switcher > .bogo-language-switcher'
      );
      // _is-openクラスがあれば削除、なければ追加
      if (element) {
        element.classList.toggle('_is-open');
        button.classList.toggle('_is-open');
      }
    });
  }
});
toggleHamburger('js-hamburger', 'js-spmenu', 1024);

document.addEventListener('DOMContentLoaded', function () {
  window.addEventListener('scroll', function () {
    // js-top-mv 要素の末端の位置を取得
    let topMvElement = document.querySelector('.js-top-mv');
    if (!topMvElement) return;
    let topMvBottom = topMvElement.offsetTop + topMvElement.offsetHeight;
    // 現在のスクロール位置を取得
    let scrollPos = window.pageYOffset || document.documentElement.scrollTop;

    // スクロール位置が js-top-mv 要素の末端を通り越した場合
    if (scrollPos > topMvBottom) {
      // js-header に _white クラスを追加
      document.querySelector('.js-header').classList.add('_white');
    } else {
      // それ以外の場合は _white クラスを削除
      document.querySelector('.js-header').classList.remove('_white');
    }
  });
});

// ['.c-heading-lv2', '.c-heading-lv3', '.js-fadein'].forEach((element) => {
//   gsap.utils.toArray(element).forEach((target) => {
//     gsap.fromTo(
//       target,
//       {
//         y: 30,
//         autoAlpha: 0,
//       },
//       {
//         y: 0,
//         autoAlpha: 1,
//         duration: 1.5,
//         ease: 'power1.out',
//         scrollTrigger: {
//           trigger: target,
//           start: 'top bottom-=15%',
//         },
//       }
//     );
//   });
// });

// [
//   '.js-fadein-group-1',
// ].forEach((element) => {
//   gsap.fromTo(
//     element,
//     {
//       y: 30,
//       autoAlpha: 0,
//     },
//     {
//       y: 0,
//       autoAlpha: 1,
//       duration: 1.5,
//       stagger: 0.2,
//       ease: 'power1.out',
//       scrollTrigger: {
//         trigger: element,
//         start: 'top bottom-=15%',
//       },
//     }
//   );
// });

document.addEventListener('DOMContentLoaded', function () {
  const elements = document.querySelectorAll(
    '.js-fadein-css, .js-fadein-group-css'
  );

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('_active');
        }
      });
    },
    {
      root: null, // ビューポートを基準にする
      rootMargin: '0px',
      threshold: 0.85, // 要素が85%（ビューポートの下から15%の位置）表示された時点でコールバックを実行
    }
  );

  // 監視対象の要素をすべて取得し、それぞれを監視する
  elements.forEach((element) => {
    observer.observe(element);
  });

  // ページ読み込み時にすでにビューポート内にある要素にクラスを付与
  elements.forEach((element) => {
    const rect = element.getBoundingClientRect();
    if (rect.top < window.innerHeight * 0.85) {
      // ビューポートの下から15%の位置にあるか
      element.classList.add('_active');
    }
  });
});

scrollToPagetopButton('js-scrollTop');
showScrollToPagetopButton('js-showScrollTopPagetopButton', ['_is-show'], 600);
